/**
 * @module NodeQueryBuilder
 * Unified GraphQL node-queries builder
 */

import { Schema } from '@base-models/Data/Schema';
import { ISchemaField } from '@base-models/Data/types';
import { SchemaNames } from '@base-constants/schemas';
import { filterMatchingNodes } from '@base-utils/regexpBuilder';

type SchemasDictionary = Record<string, Schema>;

/**
 * Builds GraphQL queries dynamically,
 * based on the list of schemas received.
 * Allows to query the node without specifying the type
 * by creating a unified project-specific node query.
 * Requires the key-value dictionary of schemas to be injected.
 */
export class NodeQueryBuilder {
  /**
 * Key-value dictionary of project's schemas
 * See the [[Schema]] interface
 */
  private schemas: SchemasDictionary = {};

  constructor(schemas: SchemasDictionary) {
    this.setSchemas(schemas);
  }

  /**
   * Replaces the schemas dictionary,
   * can be chained
   * @param schemas
   */
  public setSchemas(schemas: SchemasDictionary) {
    this.schemas = schemas;
    return this;
  }

  /**
   * Generates a subQuery for image by field name
   * @param fieldName field name
   * @example
   * ```typescript
   * queryBuilder.queryImage('descriptionImage');
   * ```
   * will output
   * ```graphql
   * descriptionImage {
   *   width
   *   height
   *   fileName
   *   dominantColor
   * }
   *```
   */
  public static queryImage(fieldName: string = 'image') {
    return `
      ${fieldName} {
        width
        height
        fileName
        dominantColor
      }
    `;
  }

  public queryPointer(fieldName: string = 'node') {
    return `
      ${fieldName} {
        displayName
        uuid
        language
        availableLanguages
        displayName
        parent {
          uuid
        }
        schema {
          name
        }
        isContainer
        breadcrumb {
          uuid
          displayName
        }
        ${this.fieldsBySchema(false)}
      }
    `;
  }

  public static getLinkedNodeUUID(fieldName: string = 'node') {
    return `
    ${fieldName} {
      uuid
    }
    `;
  }

  public getFieldsEnum(schema: Schema) {
    return schema.fields.reduce((result: string, field: ISchemaField) => {
      let fieldStr = '';
      if (field.type === 'binary') {
        fieldStr = NodeQueryBuilder.queryImage(field.name);
      } else if (field.type === 'node') {
        if (schema.name === SchemaNames.ContentPointer) {
          fieldStr = this.queryPointer(field.name);
        } else {
          fieldStr = NodeQueryBuilder.getLinkedNodeUUID(field.name);
        }
      } else if (field.type !== 'micronode') {
        fieldStr = field.name;
      }
      return `${result}
      ${fieldStr}`;
    }, '');
  }

  /**
   * Generates a subQuery for the fields of the node by its schema
   * @param schema
   * @returns fragment with fields on passed schema
   */
  public queryFields(schema: Schema) {
    const fieldsEnum: string = this.getFieldsEnum(schema);
    const result = ` ... on ${schema.name} {
      fields {
        ${fieldsEnum}
      }
    }`;
    return result;
  }

  /**
   * Generic node query builder. Creates the basic query for nodes
   * built-in props and injects additional fragments passed
   * @param uuid UUID of the node for the filter
   * @param fields fragment for the required fields (can be on multiple schemas)
   * @param additionalProps more data to be queried, if required
   */
  public static queryNode(uuid: string, language: string, fields?: string, additionalProps = '') {
    if (!uuid) {
      throw new Error('Can\'t create a nodeQuery without uuid');
    }
    return `
{
  node(uuid: "${uuid}", lang: ["${language}"]) {
    uuid
    language
    availableLanguages
    displayName
    parent {
      uuid
    }
    schema {
      name
    }
    isContainer
    breadcrumb {
      uuid
      displayName
    }

    ${fields}

    ${additionalProps}
  }
}
    `;
  }

  private fieldsBySchema(resolvePointers = true) {
    return Object.values(this.schemas)
      .filter(schema => {
        if (schema.name === SchemaNames.NavigationItem) {
          return false;
        }
        // This is required to not stuck in recursion.
        // We only need to resolve the 1st level pointers
        if (!resolvePointers && schema.name === SchemaNames.ContentPointer) {
          return false;
        }
        return true;
      })
      .map(schema => this.queryFields(schema))
      .join(`
    `);
  }

  private buildChildrenFields(
    schemaName: string,
    language: string,
    fieldsBySchema = this.fieldsBySchema(),
  ) {
    return `
    ... on ${schemaName} {
      children(lang: ["${language}"], filter: {
        fields: {
          ContentPointer: {
            name: { regex: "${filterMatchingNodes()}" }
          }
          redirection: {
            displayName: { regex: "${filterMatchingNodes()}" }
          }
          folder: {
            displayName: { regex: "${filterMatchingNodes()}" }
          }
          MediaViewer: {
            displayName: { regex: "${filterMatchingNodes()}" }
          }
          sharemagazines_webapp: {
            displayName: { regex: "${filterMatchingNodes()}" }
          }
        }
      }) {
        elements {
          uuid
          language
          availableLanguages
          displayName
          parent {
            uuid
          }
          schema {
            name
          }
          isContainer
          breadcrumb {
            uuid
            displayName
          }

          ${fieldsBySchema}

        }
      }
    }
    `;
  }

  /**
   * Basic method to create a node-query by id.
   * @param id nodes uuuid
    @example
   * ```
   * queryBuilder.queryFolder(currentNode.uuid);
   * ```
   */
  public queryNodeById(id: string, language: string, schemaName: string) {
    const schema = this.schemas[schemaName];
    const isContainer = schema?.isContainer;
    const schemaFields = this.queryFields(schema);
    const childrenFields = isContainer ? this.buildChildrenFields(schemaName, language, this.fieldsBySchema()) : '';

    return NodeQueryBuilder.queryNode(id, language, schemaFields, childrenFields);
  }

  // eslint-disable-next-line class-methods-use-this
  public querySchemaForNode(uuid: string, language: string) {
    return `
    {
      node(uuid: "${uuid}", lang: ["${language}"]) {
        uuid
        schema {
          name
        }
      }
    }
  `;
  }

  /**
   * This one is different because we don't know the ID
   */
  queryMainPage(language: string) {
    const mainPageSchema = this.schemas[SchemaNames.MainPage];
    if (!mainPageSchema) {
      throw new Error(`Project has no ${SchemaNames.MainPage} schema`);
    }

    const childrenFields = this.buildChildrenFields(
      SchemaNames.MainPage,
      language,
      this.fieldsBySchema(),
    );

    const query = `
    {
      nodes(filter: { schema: { is: ${SchemaNames.MainPage} } }, lang: ["${language || 'de'}"]) {
        elements {
          uuid,
          language,
          availableLanguages,
          parent {
            uuid
          }
          schema {
            name
          }
          isContainer
          breadcrumb {
            uuid
            displayName
          }
          isContainer,
          ${this.queryFields(mainPageSchema)}
          ${childrenFields}
        }
      }
    }
    `;
    return query;
  }

  // eslint-disable-next-line class-methods-use-this
  queryConsentPage(language: string) {
    const query = `
    {
      nodes(filter: { schema: { is: ${SchemaNames.ConsentPage} } }, lang: ["${language || 'de'}"]) {
        elements {
          uuid,
          ... on ConsentPage {
            fields {
              name
              image {
                fileName
                width
                height
                dominantColor
              }
              order
              displayName
              heading
              appInfo
              buttonText
              ppCheckbox
              ppLink
              ppCheckboxHidden
              tcCheckbox
              tcLink
              tcCheckboxHidden
              logo {
                fileName
                width
                height
                dominantColor
              }
            }
          }
        }
      }
    }`;
    return query;
  }

  // eslint-disable-next-line class-methods-use-this
  public queryMyPremiumEntertainmentPage(language: string) {
    const query = `
    {
      nodes(filter: { schema: { is: ${SchemaNames.MyPremiumEntertainment} } }, lang: ["${language || 'de'}"]) {
        elements {
          uuid
        }
      }
    }`;
    return query;
  }

  // eslint-disable-next-line class-methods-use-this
  queryNewsList(uuid: string, language: string) {
    const query = `
    {
      nodes(uuids: ["${uuid}"], lang: ["${language || 'de'}"]) {
        elements {
          uuid,
          displayName,
          ...on listfolder {
            fields {
              readMoreText
            }
          }
          children {
            elements {
              ... on news_article {
                uuid,
                fields {
                  time,
                  shortDescription,
                  news_article_headline
                }
              }
            }
          }
        }
      }
    }`;
    return query;
  }
}

const instance = new NodeQueryBuilder({});

export default instance;
